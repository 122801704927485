export const timelineItems = [
    {
        language: "de",
        date: "31.12.2024",
        title: "Ab 2025: Porzellanmal-/ Designseminare im Atelier Bad Pyrmont ",
        subtitle: "",
        content: "Für Anfragen und Infos schreiben Sie mir gern eine E-Mail an: info@melanie-foster.de",
        linkname: "Anfrage senden",
        linkpath: "mailto:info@melanie-foster.de?subject=Anfrage-Seminare-2025"
    },
    {
        language: "de",
        date: "30.12.2024",
        title: "Schließung des Webshops",
        subtitle: "",
        content: "Ab Januar 2025 steht der Shop auf meiner Website aus technischen Gründen nicht mehr zur Verfügung. Für Ihre Wünsche zu Materialbestellungen senden Sie mir bitte eine E-Mail an: info@melanie-foster.de - Mein M.Foster Medium, Pinselsets, Malmappen und diverse Bücher sind weiterhin verfügbar.",
        linkname: "Per E-Mail bestellen",
        linkpath: "mailto:info@melanie-foster.de?subject=Bestellung"
    },
    {
        language: "de",
        date: "18.06.2022",
        title: "Neuerscheinung \"Nothing but Dreams\"",
        subtitle: "",
        content: "Neu erschienenes Traumbuch von der Autorin Andreja Ligna mit Illustrationen von Melanie Foster",
        linkname: "Zum Buch",
        linkpath: "https://atelierfuermalerei.de/artikel_263"
    },
    {
        language: "de",
        date: "14.06.2021",
        title: "Es ist soweit!!",
        subtitle: "Präsenz Seminare in meinem Atelier in Deutschland/ Bad Pyrmont finden nun wieder statt!",
        content: "Rufen Sie mich direkt an oder schreiben Sie mir eine E-Mail um mögliche Termine mit Ihnen abzusprechen. Telefon mobil: +49 (0)1701796350 oder Festnetz: 05281 1606048. Online Seminare sind auch zukünftig im Online Shop buchbar. Ich freue mich auf Sie!",
        linkname: "Online Seminare",
        linkpath: "https://atelierfuermalerei.de/kategorie_5"
    },
    {
        language: "de",
        date: "30.12.2020",
        title: "Momentan ist es leider noch nicht möglich Präsenzseminare zu geben.",
        subtitle: "Ich freue mich, wenn sie in der Zwischenzeit meine beliebten Online Seminare buchen.",
        content: "Im Frühjahr 2021 werden sie wieder mein neues Seminar Programm 2021 postalisch und per email erhalten. Ich freue mich sehr darauf, sie bald wieder in meinem großen Atelier zu begrüßen. Bis dahin wünsche ich allen eine gute, gesunde und erfreuliche Zeit. Ich wünsche einen kreativen Übergang in das Neue Jahr 2021!",
        linkname: "Online Seminare",
        linkpath: "https://atelierfuermalerei.de/kategorie_5"
    },
    {
        language: "de",
        date: "30.11.2020",
        title: "Ein kleines Geschenk für meine Schüler",
        subtitle: "Sichern Sie sich einen kostenlosen Platz im Online Seminar",
        content: "Als ein kleines Geschenk zum Nikolaus verlose ich unter allen Teilnehmern Plätze für ein Online Seminar am 13. Dezember.",
        linkname: "An der Verlosung teilnehmen",
        linkpath: "https://atelierfuermalerei.de/christmasspecial/index.php"
    },
    {
        language: "de",
        date: "18.10.2020",
        title: "Online Seminare - Malmappen jetzt verfügbar",
        subtitle: "",
        content: "Die Malmappen zu allen meinen Online-Seminaren sind ab nun in meinem Webshop erhältlich. Gerne können Sie auch per E-Mail eine Bestellung abgeben.",
        linkname: "Malmappen",
        linkpath: "https://atelierfuermalerei.de/kategorie_4"
    },
    {
        language: "de",
        date: "18.05.2020",
        title: "Online Seminare",
        subtitle: "",
        content: "Die derzeitigen Reiseeinschränkungen inspirieren mich dazu für meine Malfreunde nun weltweit Online Live Seminare anzubieten. Genießen Sie den Komfort von zu Hause aus mit mir gemeinsam kreativ zu sein und gleichzeitig eine wunderbare Auszeit mit bleibenden Eindrücken zu erleben. Weitere Informationen finden Sie unter dem folgenden Link.",
        linkname: "Online Seminare",
        linkpath: "https://atelierfuermalerei.de/kategorie_5"
    },
    {
        language: "de",
        date: "12.04.2020",
        title: "Information",
        subtitle: "",
        content: "Liebe Freunde,\n" +
            "bedingt durch die Corona Pandemie verändern sich bei mir interne und externe Seminartermine.\n" +
            "Ich werde angemeldete Schüler persönlich über aktuelle Änderungen zu den Seminarterminen informieren; für evtl. Fragen zu meinen Seminaren senden Sie mir bitte eine E-Mail.\n" +
            "Die Ausstellungs- und Seminartermine im Ausland fallen für das Jahr 2020 komplett aus.\n",
        linkname: "",
        linkpath: ""
    },
    {
        language: "de",
        date: "27.12.2019",
        title: "Seminarprogramm 2020",
        subtitle: "",
        content: "Zum Download finden Sie hier das aktuelle Seminarprogramm für das Jahr 2020",
        linkname: "Seminarprogramm 2020",
        linkpath: "downloads/seminarprogramm_de_2020.pdf"
    },
    {
        language: "de",
        date: "21.12.2018",
        title: "Seminarprogramm 2019",
        subtitle: "",
        content: "Zum Download finden Sie hier das aktuelle Seminarprogramm für das Jahr 2019",
        linkname: "Seminarprogramm 2019",
        linkpath: "downloads/seminarprogramm_de_2019.pdf"
    },
    {
        language: "de",
        date: "06.06.2018",
        title: "Teilnahme",
        subtitle: "Porcelain Show 2018",
        content: "Rapperswil-Jona-Schweiz, 8.-10. November 2018",
        linkname: "",
        linkpath: ""
    },
    {
        language: "de",
        date: "26.03.2018",
        title: "Brandneue Malmappen für Rosenmotive",
        subtitle: "",
        content: "Folgen Sie dem Link um zu meinen neuen Malmappen zu gelanegen, welche ich exklusiv beim Salon de Lyon vorgestellt habe.",
        linkname: "Malmappen",
        linkpath: "https://atelierfuermalerei.de/kategorie_4"
    },
    {
        language: "de",
        date: "26.03.2018",
        title: "Teilnahme",
        subtitle: "Gast-Künstler bei der Australasian Exhibition and Convention",
        content: "August 2019",
        linkname: "",
        linkpath: ""
    },
    {
        language: "de",
        date: "24.03.2018",
        title: "Teilnahme",
        subtitle: "Salon International De Lyon",
        content: "15.-17.03.2018",
        linkname: "",
        linkpath: ""
    },
    {
        language: "de",
        date: "04.01.2018",
        title: "Neue Mobilfunknummer",
        subtitle: "",
        content: "Sie erreichen mich nun unter +49 160 760 41 75, können mich aber weiterhin noch unter der alten Nummer erreichen.",
        linkname: "",
        linkpath: ""
    },
    {
        language: "de",
        date: "12.03.2017",
        title: "Neues Buch",
        subtitle: "Melanie Foster-\"Goldedition\"",
        content: "Mein neustes Buch \"Goldedition\" erscheint im Frühjahr 2017 und ist ab jetzt im Shop bestellbar",
        linkname: "Goldedition",
        linkpath: "https://atelierfuermalerei.de/artikel_10"
    },
    {
        language: "de",
        date: "28.06.2016",
        title: "Neue Website",
        subtitle: "",
        content: "Meine neue Website, gestaltet von meinem Sohn Robin, wurde nun hochgeladen. Wenn Sie möchten, können Sie auch gerne in meinem Shop vorbei schauen.",
        linkname: "Shop",
        linkpath: "https://atelierfuermalerei.de"
    },
    {
        language: "de",
        date: "26.06.2016",
        title: "Teilnahme",
        subtitle: "ArtFest by the sea",
        content: "Am 25. Februar 2017 nehme ich am \"ArtFest by the sea\" in Tybee Island (Georgia/Savanna USA) teil",
        linkname: "",
        linkpath: ""
    },
    {
        language: "de",
        date: "16.06.2016",
        title: "Teilnahme",
        subtitle: "Salon International De Lyon",
        content: "23.-25.03.2017",
        linkname: "",
        linkpath: ""
    },
    {
        language: "de",
        date: "03.09.2015",
        title: "Neues Buch",
        subtitle: "Flower Fantasy",
        content: "Im September 2015 ist mein 4. Buch \"Flower Fantasy\", bei mir und im Verlag, erhältlich; Erst ab 2016 im Handel.",
        linkname: "",
        linkpath: ""
    },
    {
        language: "de",
        date: "10.08.2015",
        title: "Teilnahme",
        subtitle: "ArtFest by the sea",
        content: "Am 23. Februar 2016 nehme ich am \"ArtFest by the sea\" in Tybee Island (Georgia/Savanna USA) teil",
        linkname: "",
        linkpath: ""
    },
    {
        language: "de",
        date: "09.08.2015",
        title: "Teilnahme",
        subtitle: "Salon International De Lyon",
        content: "Zum Salon International De Lyon 17.-19.03.2016 bin ich als Ehrengast eingeladen.",
        linkname: "",
        linkpath: ""
    },
    {
        language: "de",
        date: "07.08.2015",
        title: "Teilnahme",
        subtitle: "Canadian Internation Convention",
        content: "Mai 2016, Montreal",
        linkname: "",
        linkpath: ""
    },
    {
        language: "en",
        date: "30th of December 2024",
        title: "From 2025: Porcelain painting/design seminars in the Bad Pyrmont studio",
        subtitle: "",
        content: "For enquiries and information, please send me an e-mail to: info@melanie-foster.de",
        linkname: "",
        linkpath: ""
    },
    {
        language: "en",
        date: "30th of December 2024",
        title: "Closure of the web shop",
        subtitle: "",
        content: "From January 2025, the shop on my website will no longer be available for technical reasons. For your requests regarding material orders, please send me an email to: info@melanie-foster.de - My M.Foster Medium, brush sets, studies and various books are still available. ",
        linkname: "Order via Mail",
        linkpath: "mailto:info@melanie-foster.de?subject=Order"
    },
    {
        language: "en",
        date: "18th of June 2022",
        title: "New release - Book \"Nothing but Dreams\"",
        subtitle: "",
        content: "Newly published dream book by the author Andreja Ligna with illustrations by Melanie Foster",
        linkname: "To the book",
        linkpath: "https://atelierfuermalerei.de/article_263"
    },
    {
        language: "en",
        date: "14th of June 2021",
        title: "The time finally has come!!!",
        subtitle: "Presence seminars in my studio in Germany/ Bad Pyrmont are now taking place again",
        content: "Call me directly or write me an email to arrange possible dates with you. Phone mobile: +49 (0)1701796350 or landline: 05281 1606048. Online seminars can also be booked in the upcoming months in the online shop. I am looking forward to meeting you!",
        linkname: "Online Seminars",
        linkpath: "https://atelierfuermalerei.de/category_5"
    },
    {
        language: "en",
        date: "30th of December 2020",
        title: "Unfortunately, it is not yet possible to give face-to-face seminars.",
        subtitle: "In the meantime, I am happy for you to book my popular online seminars.",
        content: "In spring 2021 you will again receive my new seminar program 2021 by post and email. I am very much looking forward to welcoming you back to my large studio soon. Until then I wish you all a good, healthy and enjoyable time. I wish you a creative beginning into the New Year 2021!",
        linkname: "Online Seminars",
        linkpath: "https://atelierfuermalerei.de/category_5"
    },
    {
        language: "en",
        date: "30th of November 2020",
        title: "A small gift for my students",
        subtitle: "Take your chance and enter the raffle using the link below",
        content: "As a small gift for my wonderful students, I offer an online seminar in this pre-Christmas period, for which I will raffle the places for free.",
        linkname: "Join the raffle",
        linkpath: "https://atelierfuermalerei.de/christmasspecial/index.php"
    },
    {
        language: "en",
        date: "18.10.2020",
        title: "Online Seminars - Studies now available",
        subtitle: "",
        content: "The studies of all my online seminars are now available in my webshop. Orders can also be placed by email.",
        linkname: "Studies",
        linkpath: "https://atelierfuermalerei.de/category_4"
    },
    {
        language: "en",
        date: "18th of May 2020",
        title: "Online Seminars",
        subtitle: "",
        content: "Enjoy the comfort of your own home while having a great time and a fantastic seminar with me. You can find the details at the link below.",
        linkname: "Online Seminars",
        linkpath: "https://atelierfuermalerei.de/category_5"
    },
    {
        language: "en",
        date: "12th of April 2020",
        title: "Information",
        subtitle: "",
        content: "Dear Friends,\n" +
            "due to the Corona Virus all my internally and externally seminar dates change. Registered students will be informed personally. In case of any questions feel free to send me an email.\n" +
            "All international fairs and exhibitions are cancelled in 2020.\n",
        linkname: "",
        linkpath: ""
    },
    {
        language: "en",
        date: "27th of December 2019",
        title: "Seminar program 2020",
        subtitle: "",
        content: "As download you will find the current seminar program for 2020.",
        linkname: "Seminar program 2020",
        linkpath: "downloads/seminarprogramm_en_2020.pdf"
    },
    {
        language: "en",
        date: "10th of December 2018",
        title: "Seminar program 2019",
        subtitle: "",
        content: "As download you will find the current seminar program for 2019.",
        linkname: "Seminar program 2019",
        linkpath: "downloads/seminarprogramm_en_2019.pdf"
    },
    {
        language: "en",
        date: "6th of June 2018",
        title: "Participation",
        subtitle: "Porcelain Show 2018",
        content: "Rapperswil-Jona-Schweiz, 8th-10th of November 2018",
        linkname: "",
        linkpath: ""
    },
    {
        language: "en",
        date: "24th of March 2018",
        title: "New studies with rose motifs",
        subtitle: "",
        content: "If you like to take a look at my brand new studies click the link below. They were firstly presented at Salon de Lyon 2018.",
        linkname: "Studies",
        linkpath: "https://atelierfuermalerei.de/category_4"
    },
    {
        language: "en",
        date: "24th of March 2018",
        title: "Guest Artist at",
        subtitle: "Australasian Exhibition and Convention",
        content: "August 2019",
        linkname: "",
        linkpath: ""
    },
    {
        language: "en",
        date: "1sst of February 2018",
        title: "Participation",
        subtitle: "",
        content: "I participate at Salon International De Lyon 15.-17.03.2018.",
        linkname: "",
        linkpath: ""
    },
    {
        language: "en",
        date: "28th of July 2016",
        title: "New Website",
        subtitle: "",
        content: "My new website, designed by my Son Robin, is now online. If you like, you can visit my new shop too.",
        linkname: "Shop",
        linkpath: "https://atelierfuermalerei.de"
    },
    {
        language: "en",
        date: "26th of July 2016",
        title: "Participation",
        subtitle: "",
        content: "\"ArtFest by the sea\" - 25. Februar 2017 - Tybee Island (Georgia/Savanna USA)",
        linkname: "",
        linkpath: ""
    },
    {
        language: "en",
        date: "16th of July 2016",
        title: "Participation",
        subtitle: "",
        content: "I participate at Salon International De Lyon 23.-25.03.2017.",
        linkname: "",
        linkpath: ""
    },
    {
        language: "en",
        date: "27th of February 2016",
        title: "Participation",
        subtitle: "",
        content: "\"ArtFest by the sea\" - 27. Februar 2016 - Tybee Island (Georgia/Savanna USA)",
        linkname: "",
        linkpath: ""
    },
    {
        language: "en",
        date: "10th of September 2015",
        title: "New Book",
        subtitle: "",
        content: "In september of 2015 my 4th book \"Fantasy Flowers\" is buyable from me and the publisher. It will be available in stores in 2016. ",
        linkname: "",
        linkpath: ""
    },
    {
        language: "en",
        date: "August 2015",
        title: "Participation",
        subtitle: "",
        content: "I participate at Salon International De Lyon 17.-19.03.2016 as a honorary guest.",
        linkname: "",
        linkpath: ""
    },
    {
        language: "en",
        date: "August 2015",
        title: "Participation",
        subtitle: "",
        content: "Canadian International Convention 2016 - Montreal",
        linkname: "",
        linkpath: ""
    },
    {
        language: "fr",
        date: "30th Décembre 2020",
        title: "À partir de 2025 : Séminaires de peinture/design sur porcelaine dans l'atelier de Bad Pyrmont",
        subtitle: "",
        content: "À partir de 2025 : Séminaires de peinture/design sur porcelaine dans l'atelier de Bad Pyrmont - Pour toute demande ou information, veuillez m'envoyer un e-mail à: info@melanie-foster.de",
        linkname: "",
        linkpath: ""
    },
    {
        language: "fr",
        date: "30th Décembre 2020",
        title: "Closure of the web shop",
        subtitle: "",
        content: "A partir de janvier 2025, la boutique de mon site web ne sera plus disponible pour des raisons techniques. Pour vos souhaits de commandes de matériel, veuillez m'envoyer un e-mail à: info@melanie-foster.de - Mon médium M.Foster, des sets de pinceaux, des fiche techniques de peinture et divers livres sont toujours disponibles.",
        linkname: "Commandez par mail",
        linkpath: "mailto:info@melanie-foster.de?subject=Order"
    },
    {
        language: "fr",
        date: "30th Décembre 2020",
        title: "Malheureusement, il n'est pas encore possible de donner des séminaires en face à face.",
        subtitle: "En attendant, je serais heureux que vous réserviez mes populaires séminaires en ligne.",
        content: "Au printemps 2021, vous recevrez à nouveau mon nouveau programme de séminaires 2021 par courrier et par e-mail. Je me réjouis de vous accueillir bientôt dans mon grand studio. D'ici là, je vous souhaite à tous un bon moment, sain et agréable. Je vous souhaite une transition créative vers la nouvelle année 2021!",
        linkname: "Séminaires en ligne",
        linkpath: "https://atelierfuermalerei.de/category_5"
    },
    {
        language: "fr",
        date: "27 Décembre 2019",
        title: "Programme des stages 2020",
        subtitle: "",
        content: "Cliquez ici pour télécharger le programme actuel des stages 2020",
        linkname: "Seminar programm 2020 English",
        linkpath: "downloads/seminarprogramm_en_2020.pdf"
    },
    {
        language: "fr",
        date: "07 Juin 2018",
        title: "Participation",
        subtitle: "Porcelain Show 2018 Rapperswil-Jona-Suisse",
        content: "8.-10. Novembre 2018",
        linkname: "",
        linkpath: ""
    },
    {
        language: "fr",
        date: "10 Mars 2018",
        title: "Noveaux technique brochure pour les motifs roses",
        subtitle: "",
        content: "Cliquez ici pour accèder á mes nouveaux technique brochure, que j'ai prèsentès excluisvement au Salon de Lyon.",
        linkname: "Shop",
        linkpath: "https://atelierfuermalerei.de/category_4"
    },
    {
        language: "fr",
        date: "24 Mars 2018",
        title: "Participation",
        subtitle: "",
        content: "Artiste invitè á l'exposition et á la convention Australasian. Août 2019",
        linkname: "",
        linkpath: ""
    },
    {
        language: "fr",
        date: "01 Fèvrier 2018",
        title: "Participation",
        subtitle: "",
        content: "Salon International de Lyon du 15 au 17 mars 2018.",
        linkname: "",
        linkpath: ""
    },
    {
        language: "fr",
        date: "Juin 2017",
        title: "Nouveau numèro de mobile",
        subtitle: "",
        content: "Vous pouvez me joindre maintenant sous: +49 1607604175, mais vous pouvez toujours me joindre sous l'ancien numèro.",
        linkname: "",
        linkpath: ""
    },
    {
        language: "fr",
        date: "Juin 2017",
        title: "Nouveau livre",
        subtitle: "",
        content: "Mon dernier livre \"Gold Edition\" sortira au printemps 2017 et peut être commandé dans la boutique á patir de maintenant.",
        linkname: "",
        linkpath: ""
    },
    {
        language: "fr",
        date: "28 Juin 2016",
        title: "Nouveau site web",
        subtitle: "",
        content: "Mon nouveau site, conçu par mon fils Robin, a été publié.",
        linkname: "",
        linkpath: ""
    },
    {
        language: "fr",
        date: "26 Juin 2016",
        title: "Participation",
        subtitle: "",
        content: "Le 25 février 2017 je participe à « ArtFest by the sea » à Tybee Island (Georgia/Savanna USA).",
        linkname: "",
        linkpath: ""
    },
    {
        language: "fr",
        date: "16 Juin 2016",
        title: "Participation",
        subtitle: "",
        content: "Salon International de Lyon du 23 au 25 mars 2017.",
        linkname: "",
        linkpath: ""
    },
    {
        language: "fr",
        date: "Septembre 2015",
        title: "Nouveau Livre",
        subtitle: "",
        content: "A partir de septembre 2015 mon quatrième livre « Flower Fantasy » est disponible chez l'éditeur ou chez moi. La vente officielle se fera à partir de 2016.",
        linkname: "",
        linkpath: ""
    },
    {
        language: "fr",
        date: "Février 2016",
        title: "Participation",
        subtitle: "",
        content: "Le 23 février 2016 je participe à « ArtFest by the sea » à Tybee Island à Tybee Island(Georgia/Savanna USA).",
        linkname: "",
        linkpath: ""
    },
    {
        language: "fr",
        date: "Août 2015",
        title: "Participation",
        subtitle: "",
        content: "Je serai invitée d'honneur au Salon International de Lyon du 17 au 19 mars 2016.",
        linkname: "",
        linkpath: ""
    },
    {
        language: "fr",
        date: "Août 2015",
        title: "Participation",
        subtitle: "",
        content: "Canadian International Convention 2016 à Montréal.",
        linkname: "",
        linkpath: ""
    }
];
