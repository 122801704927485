import React from "react";

export function biographyDE(){
    return(
        <div className={"col-lg-12 col-sm-12"}>
            <h2 align="center">Biographie</h2>
            <p align="center">
                Melanie Foster ist eine K&uuml;nstlerin aus Liebe zur Kunst und zum Leben.

                Bereits in der Kindheit, die sie im Weserbergland in Holzminden an der Weser (Niedersachsen) verbrachte, war sie fasziniert von der Malerei und verbrachte einen wesentlichen Teil ihrer Freizeit mit dem Malen von Bildern. Nach Ende der Schulzeit begann sie eine k&uuml;nstlerische Berufsausbildung als Malerin in der Porzellanmanufaktur F&uuml;rstenberg, die sie mit Auszeichnung absolvierte.

                In den darauf folgenden Jahren studierte sie intensiv namhafte K&uuml;nstler aus der Blumen-, Fr&uuml;chte- und Jagdmalerei, und sie befasste sich mit Golddekoration und Figurenstaffage. Neben der Freude an der Auseinandersetzung mit Motiven aus der Natur verfeinerte sie dadurch die hohe Pr&auml;zision bei der detailgetreuen Abbildung der Objekte weiter.

                Ihre fundierte Ausbildung, ihre F&auml;higkeiten und Kenntnisse sowie ihre Begeisterung f&uuml;r Farben und Formen setzte sie erfolgreich in der Produktentwicklung der Porzellanmanufaktur F&uuml;rstenberg bei Hannover um. Dort war sie unter anderem mit verantwortlich f&uuml;r die Mitarbeiter und die Auszubildenden in der Handmalerei.

                Eine sp&auml;tere Weiterbildung in der &Ouml;l- und Aquarelltechnik erm&ouml;glichte ihr, ihre Gef&uuml;hle f&uuml;r die Natur in beeindruckender Weise auch auf Leinwand und Papier zum Ausdruck zu bringen.




                Seit 1996 arbeitet sie in einem eigenen Atelier f&uuml;r gewerbliche und private Auftraggeber und ist zus&auml;tzlich seit 2006 als Fachbuchautorin t&auml;tig. Ihre k&uuml;nstlerische Spontaneit&auml;t und die individuellen W&uuml;nsche der Kunden inspirieren sie immer wieder aufs Neue. Die detailgetreue Wiedergabe von Naturobjekten auf Porzellan sch&auml;tzen mittlerweile viele Kunden in ganz Europa ebenso wie ihre gelungenen Interpretationen von Naturthemen und technischen Objekten in &Ouml;l-, Aquarell- und Acrylgem&auml;lden.

                Es ist ihr ein gro&szlig;es pers&ouml;nliches Anliegen, ihre F&auml;higkeiten an malerisch begabte Interessenten weiterzugeben. Individuelle, ausgew&auml;hlte Seminare, international, sind f&uuml;r sie deshalb fester Bestandteil ihres Schaffens.

                Dar&uuml;ber hinaus stellt sie einige ihrer Werke in ausgew&auml;hlten Ausstellungen aus.

                Seit 2011 arbeitet sie in einem neu eingerichteten Atelier im Weserbergland. In diesen R&auml;umlichkeiten entstehen die Porzellan- und Bildmalereien. Ebenso finden dort Intensivseminare zum Thema Porzellanmalerei in angenehmer Atmosph&auml;re in kleinen Gruppen statt.

            </p>
            <br />
            <h2 align="center">Vita</h2>
            <br />
            <h3 align="center">2025</h3>
            <p align="center">Raumbespielungen / &Ouml;lgem&auml;lde Hyper Photorealismus "Historische Oldtimer" f&uuml;r Privatkunden</p>
            <p align="center">Porzellanmal-/ Designseminare im Atelier Bad Pyrmont</p>
            <p align="center">Entwicklung neuer Malanleitungen</p>
            <p align="center">NEU - Entwicklung "Pers&ouml;nlichkeitsbilder</p>
            <br />
            <h3 align="center">2024</h3>
            <p align="center">Raumbespielungen / &Ouml;lgem&auml;lde Hyper Photorealismus "Historische Oldtimer" f&uuml;r Privatkunden</p>
            <p align="center">Porzellanmal-/ Designseminare im Atelier Bad Pyrmont</p>
            <p align="center">ExklusivDesign „Sportwagen“ modernes Porzellanservice für Privatkunden</p>
            <p align="center">Entwicklung neuer Malanleitungen</p>
            <br />
            <h3 align="center">2023</h3>
            <p align="center">Raumbespielungen / &Ouml;lgem&auml;lde Hyper Photorealismus "Historische Oldtimer" f&uuml;r Privatkunden</p>
            <p align="center">Porzellanmal-/ Designseminare im Atelier Bad Pyrmont</p>
            <br />
            <h3 align="center">2022</h3>
            <p align="center">Raumbespielungen / &Ouml;lgem&auml;lde Hyper Photorealismus "Historische Oldtimer" f&uuml;r Privatkunden</p>
            <p align="center">Entwicklung neuer Malanleitungen - Porzellanmalerei</p>
            <br />
            <h3 align="center">2021</h3>
            <p align="center">Online Seminare global</p>
            <p align="center">Illustrationen des Buches der Autorin Andreja Ligna "Nothing but Dreams"</p>
            <p align="center">Einrichtung Atelier &Ouml;lgem&auml;lde</p>
            <br />
            <h3 align="center">2020</h3>
            <p align="center">Online Seminare global - Aufbau eigener Streaming Platform</p>
            <p align="center">Designarbeiten f&uuml;r AMBIENTE Europe</p>
            <p align="center">Pr&auml;gender Aufenthalt nur in Deutschland</p>
            <br />
            <h3 align="center">2019</h3>
            <p align="center">“Ehrengast Europa” Australasian Exhibition and Convention Brisbane Australia</p>
            <p align={"center"}>Aufenthalt in Neuseeland</p>
            <p align={"center"}>Jury Präsidentin Porcelain Show Rapperswil Jona Schweiz</p>
            <br />
            <h3 align="center">2018</h3>
            <p align="center">Entwicklung/ Design neuer Rosenmotive für Malmappen</p>
            <p align={"center"}>Teilnahme Salon International De Lyon</p>
            <p align={"center"}>Teilnahme Porcelain Show Rapperswil Jona Suisse</p>
            <br />
            <h3 align="center">2017</h3>
            <p align="center">Ver&ouml;ffentlichung des 5. Buches "Goldedition" im Eigenverlag</p>
            <br />
            <h3 align="center">2016</h3>
            <p align="center">Ehrengast "Salon de Peinture sur Porcelaine" 17.-19. M&auml;rz</p>
            <p align="center">Participation Congres Montreal - Second prix</p>
            <br />
            <h3 align="center">2015</h3>
            <p align="center">Ver&ouml;ffentlichung des 4. Buches "Flower Fantasy"</p>
            <br />
            <h3 align="center">2014</h3>
            <p align="center">Planung des 4. Buches "Flower Fantasy"</p>
            <p align="center">Entwickelung von exklusiven Handmalereien auf Kristallglasuren f&uuml;r die Porzellanmanufaktur F&uuml;rstenberg</p>
            <br />
            <h3 align="center">2013</h3>
            <p align="center">Raumbespielungen / fotorealistische &Ouml;lgem&auml;lde f&uuml;r die Wirtgen GmbH / Windhagen</p>
            <p align="center">Pr&auml;sentation der fotorealistischen Werke f&uuml;r die Wirtgen Group auf der BAUMA M&uuml;nchen</p>
            <br />
            <h3 align="center">2012</h3>
            <p align="center">Buchautorin "Porzellanmalerei - EXOCTICA"</p>
            <p align="center">Teilnahme Ausstellung "Peinture sur porcelaine" in Lyon, Frankreich</p>
            <p align="center">Raumbespielungen / fotorealistische &Ouml;lgem&auml;lde f&uuml;r Victor Baumann GmbH & Co. KG</p>
            <br />
            <h3 align="center">2011</h3>
            <p align="center">Neues Atelier in Bad Pyrmont</p>
            <p align="center">Pr&auml;gender Aufenthalt in S&uuml;d-Korea</p>
            <br />
            <h3 align="center">2010</h3>
            <p align="center">Raumbespielungen / fotorealistische &Ouml;lgem&auml;lde f&uuml;r Petri Feinkost GmbH & Co. KG, Ottenstein</p>
            <p align="center">Pr&auml;gender Aufenthalt in Japan</p>
            <br />
            <h3 align="center">2009</h3>
            <p align="center">Raumbespielungen / fotorealistische &Ouml;lgem&auml;lde f&uuml;r V&ouml;gele AG, Mannheim; Hamm AG, Tirschenreuth; Kleemann GmbH, G&ouml;ppingen</p>
            <p align="center">Pr&auml;gender Aufenthalt in New York</p>
            <br />
            <h3 align="center">2008</h3>
            <p align="center">Buchautorin "Porzellanmalerei - Faszination Rosen"</p>
            <p align="center">Teilnahme Ausstellung in de Reehorst in Ede, Niederlande</p>
            <br />
            <h3 align="center">2007</h3>
            <p align="center">Raumbespielungen / fotorealistische &Ouml;lgem&auml;lde f&uuml;r Wirtgen GmbH, Windhagen</p>
            <p align="center">Teilnahme Ausstellung im Kultur-Casino Bern: "Porzellan malen heute"</p>
            <br />
            <h3 align="center">2006</h3>
            <p align="center">Ausstellung fotorealistischer &Ouml;lgem&auml;de, Porschezentrum Kassel</p>
            <p align="center">Teilnahme Fondation Pierre Gianadda Martigny/ VS</p>
            <p align="center">Buchautorin "Porzellanmalerei - Erlebnis Natur"</p>
            <br />
            <h3 align="center">2005</h3>
            <p align="center">Teilnahme Meissen-Troph&auml;e</p>
            <p align="center">Silbermedaille in Potsdam</p>
            <p align="center">Ausstellung fotorealistischer &Ouml;lgem&auml;lde, Mercedes Benz RKG, Bonn</p>
            <br />
            <h3 align="center">2004</h3>
            <p align="center">Ausstellung &Ouml;l-, Acrylgem&auml;lde, Hornburg/Harz</p>
            <br />
            <h3 align="center">2002-2011</h3>
            <p align="center">Malatelier in Emmerthal/L&uuml;ntorf, externe Seminare</p>
            <p align="center">Pr&auml;gende Aufenthalte in S&uuml;dafrika</p>
            <br />
            <h3 align="center">2000</h3>
            <p align="center">Ausstellung &Ouml;l-, Acrylgem&auml;lde, Burg Dringenberg, Bad Driburg</p>
            <br />
            <h3 align="center">1996</h3>
            <p align="center">Selbstst&auml;ndigkeit als Porzellanmalerin, Seminarleiterin und freischaffende K&uuml;nstlerin, Malatelier in H&ouml;xter/B&ouml;dexen.</p>
            <br />
            <h3 align="center">1993-96</h3>
            <p align="center">Mitarbeit in der Produktentwicklung der Porzellanmanufaktur F&uuml;rstenberg</p>
            <br />
            <h3 align="center">1989-92</h3>
            <p align="center">Weiterbildung in verschiedenen Maltechniken</p>
            <br />
            <h3 align="center">1980-84</h3>
            <p align="center">Ausbildung in der ehem. Herzoglich Braunschweigischen Porzellanmanufaktur F&uuml;rstenberg</p>
        </div>
    );
}

export function biographyEN(){
    return(
        <div className={"col-lg-12 col-sm-12"}>
            <h2 align="center">Biography</h2>
            <p align="center">
                Melanie Foster is an artist who is inspired by her love of art and life.

                From childhood on, which she spent in the region of Weserbergland, Holzminden, Weser (Lower Saxony), she was fascinated by the art of painting and spent a great deal of her free time with painting pictures. After her time at school she started her professional career and was trained as an artist at the porcelain factory F&uuml;rstenberg where she passed with distinction.

                In the following years, she intensively studied famous artists who painted subjects like flowers, fruit and hunting. She also dealt with gold decoration and figure staffage. Besides enjoying the painting of subjects that occur in nature, she managed to refine her skills even further by painting full particulars of the respective objects.

                She rendered her sound education, her capabilities and knowledge as well as her enthusiasm for colours and shapes successfully in product development of the porcelain factory F&uuml;rstenberg near Hannover. Among other things, she was responsible there for colleagues and trainees who studied the subject of hand painting.

                Her further education in oil and watercolour painting enabled her to express her feelings for nature in an impressive way both on canvas and paper.




                Since 1996 she has been working in her own studio both for commercial and private customers. Her artistic spontaneity and the individual requests of her customers are a continuous inspiration for her. By now, numerous customers all over Europe appreciate the particularity of nature paintings on porcelain as well as her successful interpretations of themes relating to nature and technical objects in oil, watercolour and acrylic paintings.

                It is a personal challenge for her to be able to pass on her abilities to artistically gifted persons who are interested in painting. Individually selected seminars therefore constitute an important part of her work.

                Moreover, some of her works are regularly displayed in selected exhibitions in different places all over the Federal Republic of Germany.

                Since 2011 she has been working in a newly-designed studio in the Weserbergland. This is where both her porcelain and painting works are created. Intensive seminar training with a focus on the subject of porcelain painting also takes place in these surroundings in a pleasant atmosphere in only small groups.</p>
            <br />
            <h2 align="center">Vita</h2>
            <br />
            <h3 align="center">2025</h3>
            <p align="center">Interactional room design / Oil on canvas Hyper Photorealism "Historical Oldtimer" for private customers</p>
            <p align="center">Porcelain painting/design seminars in the Bad Pyrmont studio</p>
            <p align="center">Development of new painting instructions for porcelain painting</p>
            <p align="center">NEW - Developement of "personality pictures"</p>
            <br />
            <h3 align="center">2024</h3>
            <p align="center">Interactional room design / Oil on canvas Hyper Photorealism "Historical Oldtimer" for private customers</p>
            <p align="center">Porcelain painting/design seminars in the Bad Pyrmont studio</p>
            <p align="center">ExklusivDesign "sports car" modern porcelain service for private customers</p>
            <p align="center">Development of new painting instructions for porcelain painting</p>
            <br />
            <h3 align="center">2023</h3>
            <p align="center">Interactional room design / Oil on canvas Hyper Photorealism "Historical Oldtimer" for private customers</p>
            <p align="center">Porcelain painting/design seminars in the Bad Pyrmont studio</p>
            <br />
            <h3 align="center">2022</h3>
            <p align="center">Interactional room design / Oil on canvas Hyper Photorealism "Historical Oldtimer" for private customers</p>
            <p align="center">Development of new painting instructions for porcelain painting</p>
            <br />
            <h3 align="center">2021</h3>
            <p align="center">Online seminars global</p>
            <p align="center">Illustration of the book of the author Andreja Ligna "Nothing but Dreams"</p>
            <p align="center">Furnishing of studio oil painting</p>
            <br />
            <h3 align="center">2020</h3>
            <p align="center">Online seminars global - Establishment of own streaming platform</p>
            <p align="center">Design work for AMBIENTE Europe</p>
            <p align="center">Formative stay only in Germany</p>
            <br />
            <h3 align="center">2019</h3>
            <p align="center">“Guest of honor Europe” Australasian Exhibition and Convention Brisbane Australia</p>
            <p align={"center"}>Stay in New Zealand</p>
            <p align={"center"}>Jury President Porcelain Show Rapperswil Jona Switzerland</p>
            <br />
            <h3 align="center">2018</h3>
            <p align="center">Development/ Design new Rose subjects for studies</p>
            <p align={"center"}>Participation Salon International De Lyon</p>
            <p align={"center"}>Participation Porcelain Show Rapperswil Jona Switzerland</p>
            <br />
            <h3 align="center">2017</h3>
            <p align="center">Publishing my 5th book "Goldedition"</p>
            <br />
            <h3 align="center">2016</h3>
            <p align="center">Guest of Honor, "Salon de Peinture sur Porcelaine"</p>
            <p align="center">Congres Montreal, Second Prix</p>
            <br />
            <h3 align="center">2015</h3>
            <p align="center">Published the 4th Book "Flower Fantasy"</p>
            <br />
            <h3 align="center">2014</h3>
            <p align="center">Planning of the 4th Book "Flower Fantasy"</p>
            <p align="center">Development of exclusive hand-painted on crystal glazes for porcelain manufacturer F&uuml;rstenberg</p>
            <br />
            <h3 align="center">2013</h3>
            <p align="center">Photorealistic oil paintings for the Wirtgen GmbH / Windhagen</p>
            <p align="center">Presentation of photo realistic works for the hosts Group at Bauma Munich</p>
            <br />
            <h3 align="center">2012</h3>
            <p align="center">Book author: "Porcelain painting - EXOTICA"</p>
            <p align="center">Participation in exhibition "Peinture sur porcelaine" in Lyon, France</p>
            <p align="center">Interactional room design with art / photorealistic oil paintings for Victor Baumann GmbH & Co. KG </p>
            <br />
            <h3 align="center">2011</h3>
            <p align="center">New painting studio in Bad Pyrmont</p>
            <p align="center">Formative residence in South-Korea</p>
            <br />
            <h3 align="center">2010</h3>
            <p align="center">Interactional room design with art / photorealistic oil paintings for Petri Feinkost GmbH & Co. KG, Ottenstein</p>
            <p align="center">Formative residence in Japan</p>
            <br />
            <h3 align="center">2009</h3>
            <p align="center">Interactional room design with art / photorealistic oil paintings for: V&ouml;gele AG, Mannheim; Hamm AG, Tirschenreuth; Kleemann GmbH, G&ouml;ppingen</p>
            <p align="center">Formative residence in New York</p>
            <br />
            <h3 align="center">2008</h3>
            <p align="center">Book author: "Porcelain painting - A Passion for Roses"</p>
            <p align="center">Took part in the exhibition in De Reehorst in Ede, The Netherlands</p>
            <br />
            <h3 align="center">2007</h3>
            <p align="center">Interactional room design with art / photorealistic oil paintings for Wirtgen GmbH, Windhagen</p>
            <p align="center">Participated in the exihibition "Porcelain Painting Today", Kultur Casino Bern, Switzerland</p>
            <br />
            <h3 align="center">2006</h3>
            <p align="center">Exhibition: Photorealistic oil paintings, Porschzentrum Kassel</p>
            <p align="center">Took part in the Fondation Pierre Gianadda Martigny, Switzerland</p>
            <p align="center">Book author: "Porcelain painting - Experiencing Nature"</p>
            <br />
            <h3 align="center">2005</h3>
            <p align="center">Took part in the Meissen Trophy</p>
            <p align="center">Potsdam, Germany: Silver medal winner</p>
            <p align="center">Exhibition: Photorealistic oil paintings, Mercedes Benz RKG, Bonn</p>
            <br />
            <h3 align="center">2004</h3>
            <p align="center">Exhibition: Oil and Acryl Paintings, Hornburg/Harz</p>
            <br />
            <h3 align="center">2002-2011</h3>
            <p align="center">Own painting studio in Emmerthal, Germany, gave porcelain painting seminars in other schools</p>
            <p align="center">Formative residence in South Africa</p>
            <br />
            <h3 align="center">2000</h3>
            <p align="center">Exhibition: Oil and Acryl Paintings, Castle Dringenberg, Bad Driburg</p>
            <br />
            <h3 align="center">1996</h3>
            <p align="center">Self-employed as a porcelain painter, seminar teacher, free lance artist, own painting studio in H&ouml;xter, Germany</p>
            <br />
            <h3 align="center">1993-96</h3>
            <p align="center">Worked in the product development department in the F&uuml;rstenberg manufactory</p>
            <br />
            <h3 align="center">1989-92</h3>
            <p align="center">
                Trained in different painting techniques
            </p>
            <br />
            <h3 align="center">1980-84</h3>
            <p align="center">Trained at the former Herzoglich Braunschweigische Porzellanmanufaktur F&uuml;rstenberg, Germany</p>
        </div>
    );
}

export function biographyFR(){
    return(
        <div className={"col-lg-12 col-sm-12"}>
            <h2 align="center">Biographie</h2>
            <p align="center">
                Melanie Foster est une artiste qui aime l'art et la vie. D&eacute;j&agrave; dans sa jeunesse, qu'elle a pass&eacute;
                dans le Weserbergland &agrave; Holzminden/Weser (pays montagneux de la Weser/Basse Saxe),
                elle &eacute;tait fascin&eacute;e par la peinture et passait une grande partie de son temps libre &agrave; peindre
                des tableaux.
                Apr&egrave;s sa scolarit&eacute; elle commen&ccedil;ait une formation artistique comme peintre sur porcelaine &agrave;
                la manufacture de porcelaine F&uuml;rstenberg, qu'elle r&eacute;ussissait avec distinction.
                Les ann&eacute;es qui suivirent elle &eacute;tudiait tr&egrave;s intensivement les peintures de grands peintres
                sp&eacute;cialis&eacute;s dans la peinture des fleurs, des fruits et motifs de chasse et se consacrait aussi &agrave;
                la d&eacute;coration &agrave; l'or et &agrave; la peinture de figurines.


                Outre le plaisir &agrave; se confronter aux divers motifs de la nature, elle arrivait &agrave; perfectionner sa
                pr&eacute;cision dans la reproduction de motifs copi&eacute;s minutieusement jusque dans les moindres
                d&eacute;tails.
                Sa formation solide, ses comp&eacute;tences et aptitudes ainsi que son enthousiasme pour les
                couleurs et les formes, lui permettaient de les mettre en pratique avec succ&egrave;s dans le service
                d&eacute;veloppement de nouveaux produits &agrave; la manufacture de porcelaine F&uuml;rstenberg pr&egrave;s de
                Hanovre. Elle y &eacute;tait coresponsable pour les employ&eacute;s et apprentis pratiquant la peinture &agrave;
                la main.


                Une formation ult&eacute;rieure dans les techniques &agrave; huile et &agrave; l'aquarelle lui permettait de
                reporter tout son amour pour la nature d'une mani&egrave;re &eacute;tonnante sur le papier et la toile.
                Depuis 1996 elle travaille dans son propre atelier pour le compte de clients industriels et
                priv&eacute;s.
                Sa spontan&eacute;it&eacute; artistique ainsi que les demandes individuelles des clients l'inspirent
                continuellement dans sa cr&eacute;ativit&eacute;.
                Entretemps beaucoup de clients dans toute l'Europe appr&eacute;cient ses reproductions de motifs
                de la nature peints avec pr&eacute;cision jusque dans les moindres d&eacute;tails sur porcelaine ainsi que
                ses interpr&eacute;tations de divers th&egrave;mes sur la nature et d'objets techniques sur tableaux &agrave;
                l'huile, &agrave; l'aquarelle et en acrylique.
                Son grand d&eacute;sir est de transmettre ses connaissances &agrave; des personnes talentueuses en
                peinture. Les stages font d&egrave;s lors partie int&eacute;grante de son travail artistique.
                D'autre part elle expose ses oeuvres r&eacute;guli&egrave;rement dans des expositions bien exclusives
                dans toute l'Allemagne.
                En 2011 elle ouvre son nouvel atelier au Weserbergland (pays montagneux de la Weser) ou
                elle cr&eacute;e avec une grande passion ses peintures sur porcelaine et ses tableaux.
                Ici se d&eacute;roulent aussi les stages intensifs en petits groupes dans une atmosph&egrave;re
                chaleureuse.</p>
            <br />
            <h2 align="center">Vita</h2>
            <br />
            <h3 align="center">2025</h3>
            <p align="center">Peintures à l'huile d'ambiance Hyperphotoréalisme « Voitures anciennes historiques » pour clients privés</p>
            <p align="center">Séminaires de peinture/design sur porcelaine dans l'atelier de Bad Pyrmont</p>
            <p align="center">Développement de nouveaux guides de peinture</p>
            <p align="center">NOUVEAU - Développement “Images de la personnalité”</p>
            <br />
            <h3 align="center">2024</h3>
            <p align="center">Peintures à l'huile d'ambiance Hyperphotoréalisme « Voitures anciennes historiques » pour clients privés</p>
            <p align="center">Séminaires de peinture/design sur porcelaine dans l'atelier de Bad Pyrmont</p>
            <p align="center">ExklusivDesign « voiture de sport » service moderne en porcelaine pour les clients privés.</p>
            <p align="center">Développement de nouveaux guides de peinture</p>
            <br />
            <h3 align="center">2023</h3>
            <p align="center">Peintures à l'huile d'ambiance Hyperphotoréalisme « Voitures anciennes historiques » pour clients privés</p>
            <p align="center">Séminaires de peinture/design sur porcelaine dans l'atelier de Bad Pyrmont</p>
            <br />
            <h3 align="center">2019</h3>
            <p align="center">Invité d'honneur Europe” Australasian Exhibition and Convention Brisbane Australia</p>
            <p align={"center"}>Rester en Nouvelle-Zélande</p>
            <p align={"center"}>Président du jury  Porcelain Show Rapperswil Jona Suisse</p>
            <br />
            <h3 align="center">2018</h3>
            <p align="center">Développement de nouveaux motifs roses pour les fiche brochure</p>
            <p align={"center"}>Participation Salon International De Lyon</p>
            <p align={"center"}>Participation Porcelain Show Rapperswil Jona Suisse</p>
            <br />
            <h3 align="center">2017</h3>
            <p align="center">Publishing my 5th book "Goldedition"</p>
            <br />
            <h3 align="center">2016</h3>
            <p align="center">Invit&eacute;e d'honneur au Salon de Peinture sur porcelaine, Lyon, 17.- 19. Mars</p>
            <p align="center">Congr&egrave;s Montr&eacute;al - deuxi&egrave;me prix</p>
            <br />
            <h3 align="center">2015</h3>
            <p align="center">Publication du quatri&egrave;me livre &laquo; Flower Fantasy &raquo;</p>
            <br />
            <h3 align="center">2014</h3>
            <p align="center">Cr&eacute;ation du quatri&egrave;me livre &laquo; Flower Fantasy &raquo;</p>
            <p align="center">Cr&eacute;ation de peintures exclusives &agrave; la main sur gla&ccedil;ures en cristal pour la manufacture de
                porcelaine F&uuml;rstenberg</p>
            <br />
            <h3 align="center">2013</h3>
            <p align="center">Am&eacute;nagement d'espaces int&eacute;rieurs / tableaux &agrave; l'huile, technique photo-r&eacute;aliste, pour la
                soci&eacute;t&eacute; Wirtgen GmbH/Windhagen.</p>
            <p align="center">
                Pr&eacute;sentation des oeuvres en technique photo-r&eacute;alistes pour la soci&eacute;t&eacute; Wirtgen
                GmbH/Windhagen.</p><br />
            <h3 align="center">2012</h3>
            <p align="center">Auteur du livre &laquo; Porzellanmalerei &laquo; EXOTICA &raquo;</p>
            <p align="center">Participation &agrave; l'exposition de Peinture sur Porcelaine, Lyon/France
                Am&eacute;nagement d'espaces int&eacute;rieurs / tableaux &agrave; l'huile, technique photo-r&eacute;aliste, pour la</p>
            <p align="center">soci&eacute;t&eacute; Victor Baumann Gmbh & Co.KG </p>
            <br />
            <h3 align="center">2011</h3>
            <p align="center">Nouvel atelier &agrave; Bad Pyrmont</p>
            <p align="center">S&eacute;jour marquant en Cor&eacute;e du Sud</p>
            <br />
            <h3 align="center">2010</h3>
            <p align="center">Am&eacute;nagement d'espaces int&eacute;rieurs / tableaux &agrave; l'huile, technique photo-r&eacute;aliste, pour la
                soci&eacute;t&eacute; Petri Feinkost GmbH & Co.KG, Ottenstein</p>
            <p align="center">S&eacute;jour marquant au Japon</p>
            <br />
            <h3 align="center">2009</h3>
            <p align="center">Am&eacute;nagement d'espaces int&eacute;rieurs / tableaux &agrave; l'huile, technique photo-r&eacute;aliste, pour la
                soci&eacute;t&eacute; V&ouml;gele AG, Mannheim ; Hamm AG, Tirschenreuth ; Kleemann GmbH, G&ouml;ppingen</p>
            <p align="center">S&eacute;jour marquant &agrave; New York</p>
            <br />
            <h3 align="center">2008</h3>
            <p align="center">Auteur du livre &laquo; Porzellanmalerei - Faszination Rosen &raquo;</p>
            <p align="center">Participation &agrave; l'exposition de Reehorst &agrave; Ede/Pays Bas</p>
            <br />
            <h3 align="center">2007</h3>
            <p align="center">Am&eacute;nagement d'espaces int&eacute;rieurs / tableaux &agrave; l'huile, technique photo-r&eacute;aliste, pour la
                soci&eacute;t&eacute; Wirtgen GmbH, Windhagen</p>
            <p align="center">Participation &agrave; l'exposition &laquo; peindre sur porcelaine aujourd'hui &raquo; au Casino culturel,
                Bern/Suisse</p>
            <br />
            <h3 align="center">2006</h3>
            <p align="center">Exposition de tableaux &agrave; l'huile, technique photo-r&eacute;aliste, Porschezentrum, Kassel</p>
            <p align="center">
                Participation &agrave; l'exposition peinture sur porcelaine &agrave; la Fondation Pierre Gianadda,
                Martigny/Suisse</p>
            <p align="center">Auteur du livre &laquo; Porzellanmalerei - Erlebnis Natur &raquo;</p>
            <br />
            <h3 align="center">2005</h3>
            <p align="center">Participation &agrave; la troph&eacute;e &laquo; Meissen &raquo;</p>
            <p align="center">M&eacute;daille d'argent &agrave; Potsdam</p>
            <p align="center">Exposition de tableaux &agrave; l'huile, technique photo-r&eacute;aliste, Mercedes Benz RKG, Bonn</p>
            <br />
            <h3 align="center">2004</h3>
            <p align="center">Exposition de tableaux &agrave; l'huile et en acrylique, Hornburg/Harz</p>
            <br />
            <h3 align="center">2002-2011</h3>
            <p align="center">Atelier de peinture &agrave; Emmerthal/L&uuml;ntorf, stages externes</p>
            <p align="center">S&eacute;jour marquant en Afrique du Sud</p>
            <br />
            <h3 align="center">2000</h3>
            <p align="center">Exposition de tableaux &agrave; l'huile et en acrylique, Burg Dringenberg, Bad Driburg</p>
            <br />
            <h3 align="center">1996</h3>
            <p align="center">Artiste et peintre sur porcelaine ind&eacute;pendant, charg&eacute;e de stages, Atelier de Peinture &agrave;
                H&ouml;xter/B&ouml;dexen</p>
            <br />
            <h3 align="center">1993-96</h3>
            <p align="center">Collaboration dans le service d&eacute;veloppement de nouveaux produits, Manufacture de
                porcelaine F&uuml;rstenberg</p>
            <br />
            <h3 align="center">1989-92</h3>
            <p align="center">
                Formation continue dans diverses techniques de peinture
            </p>
            <br />
            <h3 align="center">1980-84</h3>
            <p align="center">Formation &agrave; anc. Herzogliche Braunschweigischen Porzellanmanufaktur, F&uuml;rstenberg</p>
        </div>
    );
}
